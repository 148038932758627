.filterList {
    margin-bottom: 6rem;
    .content {
        gap: 20px;

        @include extra-large-desktop {
            gap: 60px;
        }

        .bilag,
        .tema,
        .kampanje,
        .merkedager {
            width: 100%;
            margin-bottom: 2rem;

            @include xxl-desktop {
                width: calc(100% / 3 - 40px);
                margin: 0;
            }

            h3 {
                color: #D46213;
                font-weight: bold;
            }

            .list {

                .item {
                    width: 100%;
                    align-items: center;
                    border-bottom: 2px solid #95D2DC;
                    padding: 12px 4px;
                    cursor: pointer;


                    a {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                    }

                    h2 {
                        color: #036475;
                        font-weight: bold;
                        font-size: 1.4rem;
                        width: 100%;

                        &:nth-child(2) {
                            margin-left: 40px;
                        }
                    }

                    img {
                        height: 30px;
                        width: 30px;
                    }

                    &:first-child {
                        border-top: 2px solid #95D2DC;
                    }
                }
            }
        }
    }
}