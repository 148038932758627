@tailwind base;
@tailwind components;
@tailwind utilities;


$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-small-desktop: 1024px;
$breakpoint-large-desktop: 1200px;
$breakpoint-xl-desktop: 1440px;


* {
    font-family: 'Roboto', sans-serif;
}

@mixin mobile {
    @media (max-width: $breakpoint-mobile) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $breakpoint-mobile + 1) and (max-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: $breakpoint-tablet + 1) and (max-width: $breakpoint-small-desktop) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: $breakpoint-small-desktop + 1) and (max-width: $breakpoint-large-desktop) {
        @content;
    }
}

@mixin extra-large-desktop {
    @media (min-width: $breakpoint-large-desktop + 1) and (max-width: $breakpoint-xl-desktop) {
        @content;
    }
}

@mixin xxl-desktop {
    @media (min-width: $breakpoint-xl-desktop + 1) {
        @content;
    }
}



@import "filter.scss";
@import "header.scss";
@import "filterList.scss";


