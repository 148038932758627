.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Subtle dark overlay */
    backdrop-filter: blur(8px); /* Glassmorphism effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;

    .modal-content {
        width: 90%;
        max-width: 600px;
        max-height: 90%;
        background: rgba(255, 255, 255, 0.95); /* Slight transparency */
        border-radius: 15px;
        padding: 25px;
        overflow-y: auto;
        position: relative;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
        animation: slideUp 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        gap: 15px;

        h2 {
            color: #036475;
            font-size: 2rem;
            font-weight: bold;
            text-align: center;
            margin-bottom: 15px;
        }

        p {
            color: #333;
            font-size: 1.1rem;
            line-height: 1.6;
            margin-bottom: 10px;

            strong {
                font-weight: bold;
                color: #036475;
            }
        }

        .close-button {
            position: absolute;
            top: 12px;
            right: 12px;
            background: #ff5959;
            color: white;
            font-size: 0.9rem;
            font-weight: bold;
            padding: 8px 14px;
            border: none;
            border-radius: 30px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                background: #d94747;
                transform: scale(1.05);
            }
        }

        .andre-datoer {
            background: #f7f9fb;
            padding: 10px 15px;
            border-radius: 10px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

            strong {
                display: block;
                font-size: 1.2rem;
                color: #036475;
                margin-bottom: 8px;
            }

            .date-badge {
                display: inline-block;
                background: #036475;
                color: white;
                padding: 6px 12px;
                font-size: 0.9rem;
                border-radius: 20px;
                margin: 5px;
                font-weight: bold;
            }
        }

        .dates-grid {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            background: #f9f9f9;
            border-radius: 10px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

            div {
                flex: 1;
                text-align: center;
            }
        }

        .links-row {
            display: flex;
            justify-content: center;
            gap: 15px;
            margin-top: 1.5rem;

            .link {
                display: flex;
                align-items: center;
                font-weight: bold;
                color: #036475;
                text-decoration: none;
                background: #e6f4f1;
                padding: 8px 14px;
                border-radius: 8px;
                transition: background 0.3s;

                .icon {
                    margin-right: 8px;
                    font-size: 1.2rem;
                }

                &:hover {
                    background: #bde0db;
                }
            }
        }

        .no-info {
            color: red;
            font-size: 1.1rem;
            font-style: italic;
            text-align: center;
        }
    }
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(15px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}
