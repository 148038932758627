.filter {
    background-color: #059BB4;
    padding: 4rem 0;

    .filter-container {
        position: relative;


        .filterOptions {
            gap: 10px;
            width: 100%;

            @include extra-large-desktop {
                width: 70%;
                margin: 0 auto;
            }

            @include xxl-desktop {
                gap: 20px;
                width: 70%;
                margin: 0 auto;
            }

            .filterSelect {
                background-color: #C3EDF5;
                border-radius: 25px;
                text-align: center;
                color: #036475;
                font-size: 1.2rem;
                font-weight: bold;
                cursor: pointer;
                transition: .3s ease-in-out;
                user-select: none;

                &:hover {
                    background-color: #036475;
                    color: #FFF;
                    transition: .3s ease-in-out;
                }

                &.active {
                    background-color: #036475;
                    color: #FFF;
                }

                &:nth-child(-n+7) {
                    padding: 2px;
                    width: calc(100% / 3 - 10px);

                    @include tablet {
                        width: calc(100% / 4 - 20px);
                    }
                    @include small-desktop {
                        width: calc(100% / 4 - 20px);
                    }

                    @include xxl-desktop {
                        padding: 4px 4px;
                        width: calc(100% / 7 - 30px);
                    }
                }

                &:nth-last-child(-n+6) {
                    padding: 2px;
                    width: calc(100% / 3 - 10px);

                    @include tablet {
                        width: calc(100% / 4 - 20px);
                    }
                    @include small-desktop {
                        width: calc(100% / 4 - 20px);
                    }

                    @include xxl-desktop {
                        padding: 6px 8px;
                        width: calc(100% / 6 - 20px);
                    }
                }
            }
        }

        .paperOption {
            margin-top: 2rem;


            @include extra-large-desktop {
                max-width: 500px;
                margin: 2rem auto 0 auto
            }

            @include xxl-desktop {
                max-width: 600px;
                margin: 4rem auto 0 auto;
            }
        }

    }
}

.paperOption {

    select {

        div {
            cursor: pointer;
        }
    }
}